import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Push Press 2-2-2-2-2 to a 2RM`}</p>
    <p>{`Band Pull Aparts 8-8-8-8-8`}</p>
    <p>{`then,`}</p>
    <p>{`4 Rounds for time of:`}</p>
    <p>{`200M Run`}</p>
    <p>{`10-Push Jerks (135/95)`}</p>
    <p>{`15-GHD Situps`}</p>
    <p>{`20-Wall Balls (20/14)`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`This Saturday, June 30th, is our free class at 9:00 & 10:00am so
bring a friend!`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`The next Strength Program will start July 1st.  Email Eric at
Fallscitystrength\\@gmail for more info. `}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      